<template>
  <v-app style="background-color: #f5faff">
    <onebox_toolbar
      :parentfolder="parentfolder"
      @loadfile="loadfolder()"
      @callparentfolder="getSourceSystem()"
      @callstorage="loadstorage()"
    ></onebox_toolbar>
    <v-content>
      <v-divider></v-divider>

      <v-overlay :value="processloader" absolute color="grey lighten-4" dark>
        <v-progress-circular indeterminate size="64" color="blue"></v-progress-circular>
        <br />
        <br />
        <span style="color: #2196F3">&nbsp; &nbsp; loading</span>
      </v-overlay>

      <v-list nav color="#f5faff">
        <v-list-item class="text-left">
          <v-list-item-avatar class="text-center">
            <v-avatar color="primary" size="35">
              <v-icon dark size="20">developer_board</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span
                style="color: #1565C0;  font-size: 18px; font-weight: 600;"
              >{{ $t('allbusinessPage.outbox.title') + ' ('+ Labeldata +")" }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card class="elevation-0" color="#f5faff" id="thiscontainer">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-expansion-panels v-model="panel" multiple outlined>
                  <v-expansion-panel class="pa-1">
                    <v-expansion-panel-header>
                      <v-layout row wrap align-center>
                        <v-flex lg2 xs6>
                          <span
                            style="font-size: 18px; font-weight: 600;"
                          >{{ $t('dcmPage.conditionFile')}}</span>
                        </v-flex>
                        <v-flex lg2 xs6>
                          <v-select
                            @change="searchdata"
                            outlined
                            dense
                            hide-details
                            return-object
                            v-model="datatype"
                            :items="listdatatype"
                            item-value="id"
                            item-text="type"
                            :disabled="checkloaddata"
                          >
                            <template v-slot:item="{ item }">{{$t(item.type)}}</template>
                            <template v-slot:selection="{ item }">{{$t(item.type)}}</template>
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-1">
                      <v-form>
                        <v-layout row wrap fill-height justify-center>
                          <v-flex xs12 sm12 md5 lg5 class="pa-1">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdatedocument"
                                    v-model="modalFromdatedocument"
                                    :return-value.sync="fromdatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        color="primary"
                                        :label="$t('myoutboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <!-- :min="setdate_(todatedocument)"
                                      :max="todatedocument" -->
                                    <v-date-picker
                                      v-model="fromdatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdatedocument()"
                                      :max="callDateNow()"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalFromdatedocument = false"
                                      >{{$t('myoutboxPage.conditionOptional.closefromdatedocument')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>

                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodatedocument"
                                    v-model="modalTodatedocument"
                                    :return-value.sync="todatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        readonly
                                        color="primary"
                                        :label="$t('myoutboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true"
                                        v-on="on"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                        :disabled="fromdatedocument === '' || fromdatedocument === null"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodatedocument.save(todatedocument)"
                                      :min="fromdatedocument"
                                      :max="setdate(fromdatedocument)"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalTodatedocument = false"
                                      >{{$t('myoutboxPage.conditionOptional.closetodatedocument')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdateupload"
                                    v-model="modalFromdateupload"
                                    :return-value.sync="fromdateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        color="primary"
                                        :label="$t('myoutboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        @click:prepend="modalFromdateupload = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <!-- :min="setdate_(todateupload)"
                                      :max="todateupload" -->
                                    <v-date-picker
                                      v-model="fromdateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdateupload()"
                                      :max="callDateNow()"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalFromdateupload = false"
                                      >{{$t('myoutboxPage.conditionOptional.closefromdateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodateupload"
                                    v-model="modalTodateupload"
                                    :return-value.sync="todateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        readonly
                                        color="primary"
                                        :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true"
                                        v-on="on"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                        :disabled="fromdateupload === '' || fromdateupload === null"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodateupload.save(todateupload)"
                                      :min="fromdateupload"
                                      :max="setdate(fromdateupload)"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                       dark
                                        color="error"
                                        @click.stop="modalTodateupload = false"
                                      >{{$t('myoutboxPage.conditionOptional.closetodateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="receivername"
                                    :label="$t('myoutboxPage.conditionOptional.receivername_')"
                                    prepend-icon="account_box"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <!-- ช่องเลือก ประเภเอกสาร -->

                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documenttype"
                                    :items="listDoctype"
                                    item-value="document_type_code"
                                    :item-text="$t('default') === 'th' ? 'document_type_th' : 'document_type_eng'"
                                    :label="$t('myoutboxPage.conditionOptional.doctype')"
                                    prepend-icon="line_style"
                                    multiple
                                  >
                                   <template v-slot:prepend-item>
                                      <v-list-item
                                        ripple
                                        @click="fn_selectall"
                                      >
                                        <v-list-item-action>
                                          <v-icon :color="documenttype.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icon }}
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            Select All
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                      <span v-if="index < maxDisplay">{{ $t('default') === 'th' ? item.document_type_th : item.document_type_eng }} </span>
                                      <span
                                        v-if="index === maxDisplay"
                                        class="grey--text caption"
                                      >(+{{ documenttype.length - maxDisplay }} ประเภท)</span>
                                    </template>
                                  </v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md12 lg12>
                                  <!-- ช่องกรอก ผู้ส่ง -->
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sendername"
                                    :label="$t('myoutboxPage.conditionOptional.sendername_')"
                                    prepend-icon="account_circle"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                          <div>
                              <div class="pt-6"  
                                @mouseover="show_info_doc = true"
                                @mouseleave="show_info_doc = false"
                                fab
                                x-small
                                  >
                                <v-icon color="primary">mdi-information</v-icon>
                                  <v-badge
                                    v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-30px;left:-35px;"
                                    :value="show_info_doc"
                                    :content="$t('balloon.info_date_doc')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge
                                    v-else
                                    style="z-index:10000;bottom:-30px;left:-27px;"
                                    :value="show_info_doc"
                                    :content="$t('balloon.info_date_doc')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                     
                              </div>
                              <div class="pt-10"  
                                @mouseover="show_info = true"
                                @mouseleave="show_info = false"
                                fab
                                x-small
                               >
                                <v-icon color="primary">mdi-information</v-icon>
                                  <v-badge
                                    v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-30px;left:-35px;"
                                    :value="show_info"
                                    :content="$t('balloon.info_date_outbox')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge
                                    v-else
                                    style="z-index:10000;bottom:-30px;left:-27px;"
                                    :value="show_info"
                                    :content="$t('balloon.info_date_outbox')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                     
                              </div>
                            </div>

                          <v-flex xs12 sm12 md1 lg1 class="text-center">
                            <v-divider vertical></v-divider>
                          </v-flex>

                          <v-flex xs12 sm12 md5 lg5 class="pa-1 text-center">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอกชื่อไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="filename"
                                    :label="$t('myoutboxPage.conditionOptional.filename')"
                                    prepend-icon="insert_drive_file"
                                  ></v-text-field>
                                </v-flex>
                                <!-- ช่องเลือกประเภทไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="fileextension"
                                    :items="listExtensionFile"
                                    item-value="data_type"
                                    item-text="data_type"
                                    :label="$t('myoutboxPage.conditionOptional.fileextension')"
                                    prepend-icon="extension"
                                  ></v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentid"
                                    :label="$t('myoutboxPage.conditionOptional.filedocumentid')"
                                    prepend-icon="format_list_numbered"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentidto"
                                    :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                    prepend-icon="format_list_numbered"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                    

                              <!-- ช่องกรอก BuyerTaxID -->
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyertaxid"
                                    :label="$t('myoutboxPage.conditionOptional.buyertaxid_')"
                                    prepend-icon="mdi-domain"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyerbranchid"
                                    :label="$t('myoutboxPage.conditionOptional.buyertaxid_')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>

                              <!-- ช่องกรอก SellerTaxID -->
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellertaxid"
                                    :label="$t('myoutboxPage.conditionOptional.sellertaxid_')"
                                    prepend-icon="mdi-domain"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellerbranchid"
                                    :label="$t('myoutboxPage.conditionOptional.sellerbranchid_')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <!-- Po_number -->
                              <v-layout row wrap justify-center >
                                  <v-flex xs12 sm12 md12 lg12>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="ponumberinput"
                                      :label="
                                        $t(
                                          'myoutboxPage.conditionOptional.ponumber'
                                        )
                                      "
                                      prepend-icon="mdi-script-text-outline"
                                    ></v-text-field>
                                  </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>

              <v-flex xs12 lg12 class="text-center">
                <br />
                <v-btn
                    outlined
                    class="ma-1"
                    color="#2D3B4E"
                    :disabled="loaddataprogress"
                    :loading="loaddataprogress"
                    @click="Clear_data()"
                      >
                      <v-icon left>mdi-refresh</v-icon>
                        {{ $t("myinboxPage.clear_data") }}
                    </v-btn>
                <v-btn
                  class="ma-1 white--text"
                  color="primary"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="searchdata()"
                >
                  <v-icon left>search</v-icon>
                  &nbsp;
                  {{ $t('myoutboxPage.search')}}
                </v-btn>
                <v-menu :close-on-content-click="closeOnContentClick">
                  <template v-slot:activator="{ on }">
                    <v-btn
                    outlined
                      class="ma-1 white-text"
                      color="primary"
                      :disabled="loaddataprogress"
                      :loading="loaddataprogress"
                      v-on="on"
                      >
                      {{$t('dowloads')}}
                      <v-icon>
                        keyboard_arrow_down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-container fluid>
                    <!-- <v-list-item> -->
                      <v-checkbox v-model="typedownloads" class="mt-0" label="Summary File Duplicate"></v-checkbox>
                      <v-checkbox v-model="typeitem" class="mt-0" label="Summary Item (1 ไฟล์ 1 บรรทัด)"></v-checkbox>
                    <!-- </v-list-item> -->
                    </v-container>
                   <v-list-item @click="exportfile(),closeOnContentClick = true" outlined @mouseover="show_download_all = true" @mouseleave="show_download_all = false">
                      <v-list-item-title>
                        <v-icon left color="primary">mdi-archive-arrow-down</v-icon>
                        {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.export')}}
                      </v-list-item-title>
                            <template>
                              <v-badge v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-27px;left:-205px;"
                                :value="show_download_all"
                                :content="$t('balloon.download_all')"
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge v-else
                                style="z-index:10000;bottom:-27px;left:-205px;"
                                :value="show_download_all"
                                :content="$t('balloon.download_all')"
                                transition="slide-y-transition"
                              ></v-badge>
                            </template>
                    </v-list-item>
                    <v-list-item @click="exportfilecsv(),closeOnContentClick = true" outlined @mouseover="show_download_all_csv = true" @mouseleave="show_download_all_csv = false">
                      <v-list-item-title>
                        <v-icon left color="primary">insert_drive_file</v-icon>
                        {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportcsv')}}
                      </v-list-item-title>
                            <template>
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-27px;left:-220px;"
                                  :value="show_download_all_csv"
                                  :content="$t('balloon.download_all_csv')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-27px;left:-220px;"
                                  :value="show_download_all_csv"
                                  :content="$t('balloon.download_all_csv')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </template>
                    </v-list-item>
                    <v-list-item @click="exportfileexcel(),closeOnContentClick = true" outlined @mouseover="show_download_all_excel = true" @mouseleave="show_download_all_excel = false">
                      <v-list-item-title>
                        <v-icon left color="primary">description</v-icon>
                        {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportexcel')}}
                      </v-list-item-title>
                            <template>
                              <v-badge v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-27px;left:-225px;"
                                :value="show_download_all_excel"
                                :content="$t('balloon.download_all_excel')"
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge v-else
                                style="z-index:10000;bottom:-27px;left:-225px;"
                                :value="show_download_all_excel"
                                :content="$t('balloon.download_all_excel')"
                                transition="slide-y-transition"
                              ></v-badge>
                        </template>
                    </v-list-item>
                </v-list>
                </v-menu>
                <!-- <v-btn
                  class="ma-1"
                  color="primary"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfile()"
                >
                  <v-icon left>mdi-archive-arrow-down</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myoutboxPage.export')}}
                </v-btn> -->
                <!-- button csv -->
                <!-- <v-btn
                  class="ma-1"
                  color="primary"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfilecsv()"
                  v-if="datatype.id != 1 "
                >
                  <v-icon left>note</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportcsv')}}
                </v-btn>
                <v-btn
                  class="ma-1"
                  color="primary"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfileexcel()"
                  v-if="datatype.id != 1 "
                >
                  <v-icon left>note</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myoutboxPage.exportexcel')}}
                </v-btn> -->
              </v-flex>

              <v-flex xs12 lg12 class="ma-4">
                <v-layout row wrap class="pl-3 pr-3">
                  <v-flex xs12 lg2>
                    <v-select
                      class="pr-2"
                      prepend-inner-icon="sort"
                      hide-details
                      solo
                      dense
                      v-model="size"
                      :items="listsize"
                      @change="clickpagination()"
                    ></v-select>
                  </v-flex>
                  <v-flex ls2 lg2>
                    <v-select
                      v-if="datatype.id == '2'"
                      class="pr-2"
                      @change="clickstatus(statusde)"
                      v-model="statusde"
                      hide-details
                      solo
                      dense
                      :items="liststatus"
                    ></v-select>
                  </v-flex>
                  <!-- <v-flex xs12 lg2>
                    <v-btn
                    @click="openreletion=true"
                    color="primary"
                    dark
                    >
                   เพิ่ม relation
                    </v-btn>
                  </v-flex> -->
                  <v-flex lg4 offset-lg4 class="pl-3 pr-2 hidden-md-and-down" v-if="sortby === 'status'">
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSortorderby"
                      color="primary"
                      dark
                      v-show="clickSortorderby"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon color="primary" v-if="checkpagination===true">mdi-chevron-up</v-icon>         
                        <v-icon
                          color="primary"
                          v-else-if="checkpagination===false"
                        >mdi-chevron-down</v-icon>
                          <span
                          :style="colorSort"
                          v-if="sortby === 'status'"
                        >{{$t('Filter.status')}}</span>                                     
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          color="primary"
                          dark
                          @click="closeclicksort"
                        >
                          <v-icon color="white">mdi-close-circle</v-icon>
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex>
                </v-layout>

                <v-layout row wrap class="ma-4">
                  <v-flex xs4 lg4>
                    <div v-if="statusmutipledownload === false" class="pt-2">
                      <v-btn
                        @mouseover="show_menu = true"
                        @mouseleave="show_menu = false"
                        color="indigo"
                        dark
                        rounded
                        class="ma-1"
                        @click="statusmutipledownload = true,show_menu = false"
                      >
                        <v-icon>mdi-menu</v-icon>
                        <p class="ml-2" style="font-size:15px;margin-bottom:2px;">{{$t('balloon.menu')}}</p>
                        <!-- &nbsp;
                        {{ $t('myoutboxPage.multipledownload')}}-->
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-40px"
                          :value="show_menu"
                          :content="$t('balloon.menu')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-55px"
                          :value="show_menu"
                          :content="$t('balloon.menu')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>
                    <div v-else class="pt-2">
                      <v-btn 
                        @mouseover="show_multipledownload = true"
                        @mouseleave="show_multipledownload = false"
                        fab 
                        small 
                        color="success" 
                        class="ma-1" 
                        @click="multipledownload()">
                        <v-icon>mdi-download-multiple</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-30px"
                          :value="show_multipledownload"
                          :content="$t('balloon.download')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-30px"
                          :value="show_multipledownload"
                          :content="$t('balloon.download')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_clear = true"
                        @mouseleave="show_clear = false"
                        fab
                        small
                        color="error"
                        class="ma-1"
                        @click="statusmutipledownload = false, clearmultipledownload(),show_clear = false"
                      >
                        <v-icon>clear</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-20px"
                          :value="show_clear"
                          :content="$t('balloon.clear')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-30px"
                          :value="show_clear"
                          :content="$t('balloon.clear')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs8 lg8 class="text-right">
                    <v-chip color="primary" dark class="ma-2" v-if="openload == true">
                      <v-icon left>mdi-domain</v-icon>
                      {{$t('myinboxPage.loading') + ' ' + loading_company + ' ' + "/" +' ' + total_download + ' ' + $t('myinboxPage.company') || $t('myinboxPage.loading') + ' ' + 0 + ' ' + $t('myinboxPage.company')}}
                    </v-chip>
                    <v-chip color="primary" dark class="ma-2">
                      <v-icon left>view_list</v-icon>
                      {{ totalfile + ' ' + $t('myoutboxPage.items') || 0 + ' ' + $t('myoutboxPage.items')}}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 lg12 class="ma-4" v-if="loaddataprogress === true">
                <div class="text-center">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </div>
              </v-flex>
              <v-flex xs12 lg12 v-else class="ma-4">
                <div v-if="resolutionScreen <= 500">
                  <!-- {{ filemultipledownload }} -->
                  <div v-if="datatype.id === 2" class="mt-2">
                    <v-list two-line class="mb-6 pb-6 elevation-1">
                      <v-list-item
                        v-for="item in outboxdata"
                        :key="item.title"
                        style="cursor: pointer;"
                      >
                        <v-list-item-avatar v-show="statusmutipledownload">
                          <v-checkbox v-model="filemultipledownload" :value="item"  v-if="item.trash !=='DELETE'"/>
                        </v-list-item-avatar>
                        <v-list-item-avatar>
                          <v-icon
                            large
                            :color="seticon(item.data_type)[1]"
                            style="font-weight: 100"
                          >{{ seticon(item.data_type)[0]}}</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content @click="previewfile(item)">
                          <v-list-item-title v-text="item.filename"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.document_no"></v-list-item-subtitle>
                          <div>
                            <!-- :color="item.status === 'pending' ? '#ffcc00':item.status === 'reject' ? 'red':item.status === 'unsend' ? '#ff8533': item.status === 'request'? 'primary':item.status === 'request_delete_log'? '#FF0000':item.status === 'pending_receiver_stoage_full'? '#ffcc00':'success'" -->
                            <v-chip
                              x-small
                              dark
                              :color="
                                item.status === 'pending'
                                  ? '#ffcc00'
                                  : item.status === 'duplicate'
                                  ? 'primary'
                                  : item.status === 'resend_from_etax'
                                  ? '#6fbf72'
                                  : item.status === 'sender_delete'
                                  ? 'error'
                                  : item.status === 'request_delete_log'
                                  ? 'error'
                                  : item.status === 'reject'
                                  ? 'warning'
                                  : 'success'
                              "
                            >
                              {{ $t("status_inbox_outbox." + item.status) }}
                              <!-- {{item.status === 'request_delete_log' ? 'ขอลบ' : item.status}} -->
                            </v-chip>
                             <v-icon 
                              v-if="item.trash === 'DELETE'"
                              color="red"
                              small
                              >
                              cancel_presentation
                              </v-icon>
                              <v-icon
                              v-else-if="item.trash === 'T'"
                              color="amber"
                              small
                              >
                              delete_sweep
                            </v-icon>
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-btn class="elevation-0" fab small v-on="on">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="opendialog = true , newdata=item">
                                <v-list-item-icon>
                                  <v-icon>mdi-information</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myoutboxPage.buttonAction.fileDetail')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- @click="openoutbox = true, newdata=item" -->
                              <v-list-item @click="openoutbox = true, newdata=item" disabled>
                                <v-list-item-icon>
                                  <v-icon>folder</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myoutboxPage.buttonAction.relatedFile')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item @click="downloadfile(item)" 
                              v-if="item.trash !== 'DELETE' "
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-download-multiple</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myoutboxPage.buttonAction.download')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                                  <!-- <v-list-item @click="opendialogdelete = true ,data=item ,statusdelete='N'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                             <v-list-item @click="opendialogdelete = true ,data=item ,statusdelete='Y'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                               <!-- request_delete_log -->
                            <v-list-item
                              v-if="item.status === 'request_delete_log'"
                            >
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="pr-2">
                                <v-btn color="green" dark dense @click="opendialogrequestdelete = true , data=item , statusrequestdelete ='Y'">Delete</v-btn>
                              </v-list-item-content>
                              <v-list-item-content>
                                <v-btn
                                  color="red"
                                  dark
                                  dense
                                  @click="deleterequest(item,'N')"
                                >No</v-btn>
                              </v-list-item-content>
                            </v-list-item>
                              <v-list-item v-if="item.trash !== 'DELETE'  &&  item.trash !== 'T' && item.status !== 'request' && item.status !== 'unsend'">
                              <v-list-item-icon>
                                <v-icon>
                                  sync_alt
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="buntonRequest(item)" 
                                  color="primary"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Request
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      color="primary"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination>
                  </div>
                  <div v-else class="mt-2">
                    <v-list two-line class="mb-6 pb-6 elevation-1">
                      <v-list-item
                        v-for="item in paginatedsenderData"
                        :key="item.sender_name"
                        style="cursor: pointer;"
                      >
                        <v-list-item-avatar v-show="statusmutipledownload">
                          <v-checkbox v-model="filemultipledownload" :value="item" v-if="item.trash !=='DELETE'"/>
                        </v-list-item-avatar>
                        <v-list-item-avatar>
                          <v-icon large color="primary" style="font-weight: 100">business</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title v-text="item.sender_name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <!-- <v-btn class="elevation-0" fab small @click="downloadfile(item)">
                          <v-icon>mdi-download</v-icon>
                          </v-btn>-->
                          <v-chip
                            v-if="item.sum_receive >0"
                            class="ma-2"
                            color="red"
                            text-color="white"
                          >{{ item.sum_receive}}</v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <br />
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      color="primary"
                      :total-visible="10"
                    ></v-pagination>
                  </div>
                </div>
                <div v-else>
                  <!-- Table by Company -->
                  <div v-if="datatype.id === 2">
                    <v-data-table
                      v-model="filemultipledownload"
                      :headers="headers"
                      :items="outboxdata"
                      item-key="sender_id"
                      class="elevation-1"
                      hide-default-footer
                      :single-select="true"
                      :show-select="statusmutipledownload"
                      :page.sync="page"
                      :items-per-page="size"
                      @page-count="pageCount = $event"
                    >
                       <template v-slot:[`header.data_type`]="{ header }">
                        <span 
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                        @click="sortby='status',clickSortorderby=true ,checkpagination=!checkpagination ,check()"
                        >{{header.text}}</span>
                      </template>
                      <!-- <template v-slot:header.data_type="{ header }"></template> -->
                      <template  v-slot:[`header.filename`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.document_no`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.document_date`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.document_type`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.sender_name`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.receiver_name`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.cre_dtm`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.file_id`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:item="props">
                        <tr>
                          <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="props.item" v-if="props.item.trash !=='DELETE'"/>
                          </td>
                          <td width="5%" class="text-center">
                              <v-badge
                              color="red"
                              overlap
                              icon="clear"
                              small
                              v-if="props.item.trash === 'DELETE' "
                            >
                          <v-icon
                            large
                            :color="seticon(props.item.data_type)[1]"
                          >{{ seticon(props.item.data_type)[0]}}</v-icon>
                             </v-badge>
                            <v-badge
                              color="amber"
                              overlap
                              icon="delete_sweep"
                              small
                              v-else-if="props.item.trash === 'T' "
                            >
                          <v-icon
                            large
                            :color="seticon(props.item.data_type)[1]"
                          >{{ seticon(props.item.data_type)[0]}}</v-icon>
                             </v-badge>
                            <v-icon
                            v-else
                              large
                              :color="seticon(props.item.data_type)[1]"
                            >{{ seticon(props.item.data_type)[0]}}</v-icon>
                            <br />
                            <!-- :color="props.item.status === 'pending' ? '#ffcc00':props.item.status === 'reject' ? 'red':props.item.status === 'unsend' ? '#ff8533': props.item.status === 'request'? 'primary':props.item.status === 'request_delete_log'? '#FF0000':props.item.status === 'pending_receiver_stoage_full'? '#ffcc00':'success'" -->
                            <v-chip
                              class="ma-1"
                              x-small
                              dark
                              :color="
                                props.item.status === 'pending'
                                  ? '#ffcc00'
                                  : props.item.status === 'duplicate'
                                  ? 'primary'
                                  : props.item.status === 'resend_from_etax'
                                  ? '#6fbf72'
                                  : props.item.status === 'sender_delete'
                                  ? 'error'
                                  : props.item.status === 'request_delete_log'
                                  ? 'error'
                                  : props.item.status === 'reject'
                                  ? 'warning'
                                  : 'success'
                              "
                            >
                              {{ $t("status_inbox_outbox." + props.item.status) }}
                              <!-- {{props.item.status === 'request_delete_log' ? 'ขอลบ' :props.item.status}} -->
                            </v-chip>
                          </td>
                          <td
                            width="5"
                            :title="props.item.filename"
                          >{{ props.item.filename | subStr}}</td>
                          <td width="10%">{{ props.item.document_no}}</td>
                          <td
                            width="15%"
                            class="text-center"
                          >{{ setformatdate(props.item.document_date)}}</td>
                          <td width="10%" class="text-center"><v-chip Default color="white">{{ props.item.document_type }}</v-chip></td>
                          <td width="10%" class="text-center"><v-chip Default color="white">{{props.item.sender_name}}</v-chip></td>
                          <td width="10%" class="text-center"><v-chip Default color="white">{{props.item.receiver_name}}</v-chip></td>
                          <td
                            width="15%"
                            class="text-center"
                          >{{ formatdatetime(props.item.cre_dtm)}}</td>
                          <td width="5%" class="text-center">
                            <v-menu>
                              <template v-slot:activator="{ on }">
                                <v-btn class="elevation-0" fab small v-on="on">
                                  <!-- @click="downloadfile(props.item) -->
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="opendialog =true, newdata=props.item" >
                                  <v-list-item-icon>
                                    <v-icon>mdi-information</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{$t('myoutboxPage.buttonAction.fileDetail')}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <!-- @click="openoutbox = true, newdata=props.item" -->
                                <v-list-item @click="openoutbox = true, newdata=props.item" disabled>
                                  <v-list-item-icon>
                                    <v-icon>folder</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{$t('myoutboxPage.buttonAction.relatedFile')}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="downloadfile(props.item)" 
                                v-if="props.item.trash !== 'DELETE' "
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-download-multiple</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{$t('myoutboxPage.buttonAction.download')}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                        <!-- <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true,data=props.item,statusdelete='N'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true,data=props.item,statusdelete='Y'"
                                disabled
                              >
                                <v-list-item-icon>
                                  <v-icon>delete_forever</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                               <v-divider v-if="props.item.status === 'request_delete_log'"></v-divider>
                              <v-list-item v-if="props.item.status === 'request_delete_log'">
                              <v-list-item-icon>
                                <v-icon>
                                  delete
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="opendialogrequestdelete = true ,data=props.item,statusrequestdelete='Y'" 
                                  color="green"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Delete
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                                <v-list-item-content class="pl-2">
                                  <v-btn @click="deleterequest(props.item,'N')" 
                                  color="red"
                                  dark
                                  >
                                    <v-list-item-title>
                                        No
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                                <v-list-item v-if="props.item.trash !== 'DELETE'  &&  props.item.trash !== 'T' && props.item.status !== 'request' && props.item.status !== 'unsend'">
                              <v-list-item-icon>
                                <v-icon>
                                  sync_alt
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="buntonRequest(props.item)" 
                                  color="primary"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Request
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <div class="text-xs-center">
                      <br />
                      <v-pagination
                        v-model="page"
                        :length="pageCount"
                        color="primary"
                        :total-visible="10"
                        @input="clickpagination()"
                      ></v-pagination>
                    </div>
                  </div>
                  <!-- Table by Files -->
                  <div v-else>
                    <v-data-table
                      v-model="filemultipledownload"
                      :headers="headerscompany"
                      :items="outboxdata"
                      item-key="sender_id"
                      :no-results-text="nodata"
                      :show-select="statusmutipledownload"
                      class="elevation-1"
                      :single-select="true"
                      :page.sync="page"
                      :items-per-page="size"
                      :hide-default-footer="true"
                      @page-count="pageCount = $event"
                    >
                      <template  v-slot:[`header.system_type`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ header.text }}</span>
                      </template>
                      <template  v-slot:[`header.fullname_th`]="{ header }" v-if="$i18n.locale === 'th'">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>

                      <template  v-slot:[`header.fullname_eng`]="{ header }" v-else>
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:[`header.sum_receive`]="{ header }">
                        <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                        >{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:item="props">
                        <tr style="cursor: pointer" @dblclick="gotosourcesystem(props.item)">
                          <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="props.item" v-if="props.item.trash !=='DELETE'"/>
                          </td>
                          <td class="text-center">
                            <v-icon large color="primary" style="font-weight: 100">business</v-icon>
                          </td>
                          <!-- <td v-if="$i18n.locale === 'th'">{{ props.item.fullname_th}}</td>
                          <td v-else>{{ props.item.fullname_eng}}</td>-->
                          <td>{{ props.item.sender_name}}</td>
                          <td class="text-center">
                            <v-chip
                              v-if="props.item.sum_receive >0"
                              class="ma-2"
                              color="red"
                              text-color="white"
                            >{{ props.item.sum_receive}}</v-chip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <div class="text-xs-center">
                      <br />
                      <v-pagination
                        v-model="page"
                        :length="pageCount"
                        color="primary"
                        :total-visible="10"
                      ></v-pagination>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <outboxdetail :show="openoutbox" @closedialog="openoutbox= false" :fileoutbox="newdata" :filedata="outboxdata" />
          <dialogdetailoutbox
            :show="opendialog"
            @closedialog="opendialog= false"
            :fileoutbox="newdata"
          />
          <previewfile
            :show="openpreviewfile"
            :filedata="currentfile"
            @closepreviewfile="openpreviewfile = false"
            @openpreviewfile="openpreviewfile = true"
          ></previewfile>
          <insertrelation
            :show="openreletion"
            :outboxdata="outboxdata"
            @closedialog="openreletion= false"
          ></insertrelation>
          <dialogcheckrequestdelete
            :show="opendialogrequestdelete"
            @closedialog="opendialogrequestdelete=false"
            @reload="getOutboxDataV3()"
            :data="data"
            :status="statusrequestdelete"
          ></dialogcheckrequestdelete>
          <dialogcheckdelete
            :show="opendialogdelete"
            @closedialog="opendialogdelete=false"
            @reload="getOutboxDataV3()"
            :data="data"
            :status="statusdelete"
          ></dialogcheckdelete>
          <dialogsnackbermultidowload
            :show="opensnackbarmultidownload"
            @closedialog="opensnackbarmultidownload=false"
            :percent="percent"
            :date='datetime'
            :namedowload="namedowload"
            :btsdownload="btsdownload"
            :count_loadingfile="count_loadingfile"
            :count_allfile="count_allfile"
            :statusloading="statusloading"
            :showtotalfileonly="showtotalfileonly"
          ></dialogsnackbermultidowload>
          <dialogalersearchdonotfillout
            :show="opendialogalersearchdonotfillout"
            @closedialog="opendialogalersearchdonotfillout = false"
          ></dialogalersearchdonotfillout>
          <dialogspecifyconditions
            :show="opendialogspecifyconditions"
            @closedialog="(opendialogspecifyconditions) = false"
          ></dialogspecifyconditions>
                     <!-- snackbardowloadfile 1 file -->
   <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbardowloadonefile"
      bottom
      color="white"
    ><v-list color="white" style="padding-top: 0px;" >
    <v-toolbar color="primary" dense short dark flat fab>{{$t('myinboxPage.dowload.dowloading')}}<v-spacer></v-spacer><v-btn
        color="red"
        text
        @click="snackbardowloadonefile = false"
        fab
        x-small
      ><v-icon>mdi-window-close</v-icon>
      </v-btn></v-toolbar><v-list-item
      dense 
      style="height:40px; width:400px;"    
     ><v-list-item-content >
            <v-list-item-title > {{namedowload}}</v-list-item-title>
          </v-list-item-content>
          <v-progress-circular        
          :rotate="90"
          :size="35"
          :width="3"
          :value= percentCompleted
          color="teal"
          style="margin-left:25px"
        >&nbsp; {{ percentCompleted }}
        </v-progress-circular>
          </v-list-item></v-list>       
    </v-snackbar>
     </div>
          <!-- snackbardowloadfileทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotal" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar color="primary" dense short dark flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotal = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title style="color:black">{{ExportOutboxFiles}}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>

          <!-- snackbardowloadfilecsv ทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotalcsv" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar color="primary" dense short dark flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotalcsv = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title style="color:black">{{ExportOutboxFilescsv}}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>
              <!-- snackbardowloadfileexcel ทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotalexcel" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar color="primary" dense short dark flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotalexcel = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title style="color:black">{{ExportOutboxFilesexcel}}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>
        </v-card-text>
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
import axios, { CancelToken } from 'axios';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import { saveAs } from "file-saver";
import format from "date-fns/format";
import onebox_toolbar from "@/components/layout/layout-business-toolbar";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
// const onebox_toolbar = () => import("../components/layout/layout-toolbar");
const previewfile = () => import("@/components/optional/dialog-preview");
const dialogdetailoutbox = () =>
  import("@/components/optional/dialog-detailoutbox");
const outboxdetail = () => import("@/components/optional/dialog-outboxdetail");
const insertrelation = () => import("@/components/optional/dialog-insertrelationoutbox")
const dialogcheckdelete = () => import("@/components/optional/dialog-checkdeleteoutbox");
const dialogcheckrequestdelete = () => import("@/components/optional/dialog-checkrequestdeleteoutbox");
const dialogsnackbermultidowload =() => import("@/components/optional/dialog-snackbarmultidowmload");
const dialogalersearchdonotfillout = () => import("@/components/optional/dialog-search-donotfillout.vue");
const dialogspecifyconditions = () => import("@/components/optional/dialog-newalert-inbox-outbox.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
  name: "alloutbox_system",
  components: { onebox_toolbar, previewfile, dialogdetailoutbox, outboxdetail ,insertrelation ,dialogcheckdelete,dialogcheckrequestdelete,dialogsnackbermultidowload,dialogalersearchdonotfillout,dialogspecifyconditions},
  data: function() {
    return {
      showtotalfileonly: false,
      opendialogspecifyconditions:false,
      opendialogalersearchdonotfillout:false,
      show_info_doc:false,
      show_info:false,
      show_menu:false,
      show_multipledownload:false,
      show_clear:false,
      show_download_all: false,
      show_download_all_csv: false,
      show_download_all_excel: false,
      typedownloads:false,
      typeitem:false,
      statusloading:"",
      openload:false,
      total_download:0,
      loading_company:0,
      count_loadingfile:0,
      count_allfile:0,
      btsdownload:false,
      opensnackbarmultidownload:false,
      datetime: "",
      percent: 0,
      closeOnContentClick:false,
      namedowload:"",
      maxDisplay:1,
      checkloaddata:false,
      opendialogrequestdelete:false,
      opendialogdelete:false,
      data:'',
      statusdelete:'',
      statusrequestdelete:'',
      sortby: "",
      clickSortorderby: false,
      checkpagination: false,
      openreletion:false,
      percentCompleted: 0,
      snackbardowloadonefile:false,
      snackbardowloadonetotal: false,
      snackbardowloadonetotalcsv: false,
      snackbardowloadonetotalexcel: false,
      ExportOutboxFiles: "ExportOutboxFiles",
      ExportOutboxFilescsv: "ExportOutboxFilescsv",
      ExportOutboxFilesexcel: "ExportOutboxFilesexcel",
      sortStatus: "",
      // statusde: "Active",
      // liststatus: ["All", "Active", "Pending", "Reject","Request","Unsend"],
      // liststatus: ["Active", "Pending","Duplicate","request for removal sent"],
      statusde: "All",
      // liststatus: ["All", "Active", "Pending", "Reject","Request","Unsend"],
      // liststatus: ["All","Active", "Pending","Duplicate","request for removal sent","receiver denied request","resend from etax"],
      liststatus: ["All","Active", "Pending","Duplicate","Resend From Etax","Sender Delete","Request For Removal Sent","Reject"],
      newdata: [],
      openoutbox: false,
      opendialog: false,
      datatype: { id: 1, type: "myoutboxPage.typecompany" },
      listdatatype: [
        { id: 1, type: "myoutboxPage.typecompany" },
        { id: 2, type: "myoutboxPage.typefiles" }
      ],
      filemultipledownload: [],
      statusmutipledownload: false,
      listsize: [20, 50, 100],
      defaulticon: "",
      listExtensionFile: [
        {
          data_type: "pdf"
        },
        {
          data_type: "xml"
        }
      ],
      listDoctype:[],
      //  listDoctype: [
      //   {
      //     document_type: "ใบเพิ่มหนี้",
      //     document_type_code:"80"
         
      //   },
      //   {
      //     document_type: "ใบลดหนี้",
      //     document_type_code:"81"
      //   },
      //   {
      //     document_type: "ใบส่งของ",
      //     document_type_code:"207"
      //   },
      //   {
      //     document_type: "บัญชีสินค้าเดินพิธี",
      //     document_type_code:"325"
      //   },
      //   {
      //     document_type: "ใบแจ้งหนี้",
      //     document_type_code:"380"
      //   },
      //      {
      //     document_type: "ใบลดหนี้การค้า",
      //     document_type_code:"381"
      //   },
 
      //    {
      //     document_type: "ใบเพิ่มหนี้การค้า",
      //     document_type_code:"383"
      //   },
      //   {
      //     document_type: "ใบกำกับภาษี",
      //     document_type_code:"388"
      //   },
      //   {
      //     document_type: "ใบเสร็จค่า Freight",
      //     document_type_code:"623"
      //   },
      //   {
      //     document_type: "ใบรับ (ใบเสร็จรับเงิน)",
      //     document_type_code:"T01"
      //   },
      //   {
      //     document_type: "ใบแจ้งหนี้/ใบกำกับภาษี",
      //     document_type_code:"T02"
      //   },
      //   {
      //    document_type: "ใบเสร็จรับเงิน/ใบกำกับภาษี ",
      //     document_type_code:"T03"
      //   },
      //   {
      //    document_type: "ใบส่งของ/ใบกำกับภาษี",
      //     document_type_code:"T04"
      //   },
      //    {
      //    document_type: "ใบกำกับภาษีอย่างย่อ",
      //     document_type_code:"T05,T06"
      //   },
      //   {
      //    document_type: "ใบยกเลิก",
      //     document_type_code:"T07"
      //   },
      // ],
      currentfile: {},
      openpreviewfile: false,
      nodata: this.$t("myoutboxPage.Nodata"),
      panel: [0],
      ponumberinput: "",
      filename: "",
      fileextension: "",
      documentid: "",
      documentidto: "",
      documenttype: [],
      sendername: "",
      systemname: "",
      sellertaxid: "",
      sellerbranchid: "",
      receivername: "",
      buyertaxid: "",
      buyerbranchid: "",
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      fromdatedocument: "",
      modalFromdatedocument: false,
      todatedocument: "",
      modalTodatedocument: false,
      loaddataprogress: false,
      outboxdata: [],
      headers: [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "data_type",
          width: "5%"
        },
        {
          text: "tablefile.filenameonly",
          align: "left",
          value: "filename",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.docno",
          align: "left",
          value: "document_no",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.docdate",
          align: "left",
          value: "document_date",
          sortable: false,
          width: "15%"
        },
        {
          text: "tablefile.doctype",
          value: "document_type",
          align: "left",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.sendername_",
          value: "sender_name",
          align: "left",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.receivername_",
          value: "receiver_name",
          align: "left",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.outboxdate",
          value: "cre_dtm",
          align: "left",
          sortable: false,
          width: "15%"
        },
        {
          text: "tablefile.download",
          value: "file_id",
          align: "left",
          sortable: false,
          width: "5%"
        }
      ],
      headerscompanyth: [
        {
          text: "",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false
        },
        {
          text: "allbusinessPage.tablebusinesslist.business",
          align: "left",
          value: "fullname_th",
          width: "83%",
          sortable: false
        },
        {
          text: "allbusinessPage.tablebusinesslist.record",
          align: "center",
          value: "sum_receive",
          width: "10%",
          sortable: false
        }
      ],
      headerscompanyeng: [
        {
          text: "",
          align: "left",
          value: "file_type",
          width: "7%",
          sortable: false
        },
        {
          text: "allbusinessPage.tablebusinesslist.business",
          align: "left",
          value: "fullname_eng",
          width: "83%",
          sortable: false
        },
        {
          text: "allbusinessPage.tablebusinesslist.record",
          align: "center",
          value: "sum_receive",
          width: "10%",
          sortable: false
        }
      ],
      fromdate: "",
      fromdate_hint: "",
      todate_hint: "",
      size: 20,
      page: 1,
      totalfile: 0
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = 0;
        if (this.datatype["id"] === 1) {
          l = this.outboxdata.length;
        } else {
          l = this.totalfile;
        }

        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    parentfolder() {
      return this.dataAccountActive["directory_id"];
    },
    Labeldata: {
      get() {
        return this.$route.query.sourcesystem || "-";
      }
    },
    headerscompany() {
      if (this.$t("default") === "th") {
        return this.headerscompanyth;
      } else {
        return this.headerscompanyeng;
      }
    },
    // ทำ Pagination กดดู
    paginatedsenderData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.outboxdata.slice(start, start + this.size);
    },
    icon(){
      if (this.Alldoctype) return 'mdi-close-box'
      if (this.Somedoctype) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    Alldoctype(){
      return this.documenttype.length === this.listDoctype.length
    },
    Somedoctype(){
        return this.documenttype.length > 0 && !this.Alldoctype
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    }
  },
  methods: {
    setdate_(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() - 90);
        console.log(date);
        return new Date(date).toISOString().substr(0, 10);
      }
      return date_;
    },
    setdate(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        var dateNow = new Date();
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() + 90);
        console.log(date);
        // return new Date(date).toISOString().substr(0, 10);
        if (date > dateNow) {
          return new Date(dateNow).toISOString().substr(0, 10);
        } else {
          console.log(date);
          return new Date(date).toISOString().substr(0, 10);
        }
      }
      return date_;
      // if(date_ === ''){
      //   var date = new Date(),
      //     y = date.getFullYear(),
      //     m = date.getMonth(),
      //     d = date.getDate() + 90;
      //   console.log("d", d);
      //   var Day = new Date(y, m, d).toISOString().substr(0, 10);
      //   console.log(Day);
      //   return Day;
      // }
    },
    fn_selectall(){
      let listdocument = []
        this.$nextTick(() => {
          console.log(this.Alldoctype);
          if (this.Alldoctype) {
            this.documenttype = []
            console.log("documenttype",this.documenttype);
          } else {
            for(let i = 0; i < this.listDoctype.slice().length; i++ ){
                listdocument.push(this.listDoctype[i].document_type_code)
            }
            this.documenttype = listdocument
            console.log("documenttype else",this.documenttype);
          }
        })
      },
    //  deleteinbox(item_ , status){
    //   console.log(item_);
    //   console.log(status);
    //   let payload = {
    //     account_id:this.dataAccountId,
    //     inbox_id:[item_.inbox_id],
    //     send_request: status,
    //     delete_log:"outbox",
    //     user_id:this.dataUsername
    //   }
    //   console.log("payload",payload);
    //     this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_log_inbox",
    //       payload
    //     )
    //     .then((response) => {
    //       console.log(response);
    //       if (response.data.status === "OK") {
    //         if(status === 'N'){
    //            Toast.fire({
    //           icon: "success",
    //           title: "Delete Success",
    //         });
    //         this.getOutboxDataV3()
    //         }else if(status === 'Y'){
    //             Toast.fire({
    //           icon: "success",
    //           title: "Delete & Request Success",
    //         });
    //         this.getOutboxDataV3()
    //         }           
    //       }
    //     });
    // },
    async deleterequest(_item ,status){
      console.log(_item,status);
      let payload = {
        account_id:this.dataAccountId,
        inbox_id:[_item.inbox_id],
        request_answer:status,
        delete_log:"outbox",
        user_id:this.dataUsername
      }
      let auth = await gbfGenerate.generateToken();
      console.log("payload",payload);
       await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_log_inbox_requset",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            if(status === 'Y'){
               Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            this.getOutboxDataV3()
            }else if(status === 'N'){
                Toast.fire({
              icon: "success",
              title: "Success",
            });
            this.getOutboxDataV3()
            }           
          }
        });
    },
     async buntonRequest(item){
      let payload= {
         inbox_id:[item.inbox_id],
         status:"request",
         user_id:"",
         account_id:this.dataAccountId
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
     await this.axios
      .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/set_unsend_inbox",
            payload,{headers: { Authorization: auth.code },}
      )
      .then((response) =>{
        console.log(response);
        if(response.data.status === 'OK'){
             Toast.fire({
                icon: "success",
                title: "Success",
              });
              this.check()
              // this.loadDataOutboxV3()
        }
      })

      
    },
  closeclicksort(){
    this.clickSortorderby = false;
    this.checkpagination = false;
    this.check();
  },
  check(){
    if(this.checkpagination === true){
      this.checksortorderby();
    }else{
      this.searchdata();
    }
  },
    checksortorderby(){
      this.outboxdata = [];
      let business = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        business.push(this.dataBusinessProfile[i]["business_id"]);
      }
      this.sortorderby(business, "2");
    },
    async sortorderby(_accountid, _type) {
      this.checkloaddata = true;
      this.loaddataprogress = true;
      let offset = this.size * (this.page - 1);
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: _accountid,
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername  === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v3/get_file_outbox",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(res => {
          if (res.data.status === "OK") {
            this.checkloaddata = false;
            // this.outboxdata = [];
            if ("total_file" in res["data"]["result"]) {
              this.totalfile = res.data.result["total_file"] || 0;
              for(let i=0 ; i < res.data.result.data.length ; i++){
              //   // if(res.data.result.data[i].relation_status === 0 || res.data.result.data[i].relation_status === 2){
                  this.outboxdata.push(res.data.result.data[i])
              //   // }
              }
              console.log(this.outboxdata);
              // this.outboxdata = res.data.result["data"];
            } else {
              this.totalfile = 0;
              this.outboxdata = [];
            }
            this.loaddataprogress = false;
          } else {
            this.checkloaddata = false;
            this.loaddataprogress = false;
            this.totalfile = 0;
          }
        })
        .catch(error => {
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
    },
    clickstatus(val) {
      console.log("val", val);
      this.page = 1;
      let sortStatus = "";
      if (val === "All") {
        this.sortStatus = "";
      } else if (val === "Active") {
        this.sortStatus = "active";
      } else if (val === "Pending") {
        this.sortStatus = "pending";
      } else if (val === "Reject") {
        // this.sortStatus = "reject";
        this.sortStatus = 'receiver_denied_request'
      }else if(val === "Request"){
        this.sortStatus = 'request'
      }else if(val === "Unsend"){
        this.sortStatus = 'unsend'
      }else if(val === "Duplicate"){
        this.sortStatus = 'duplicate'
      }else if(val === "Request For Removal Sent"){
        this.sortStatus = 'request_for_removal_sent'
      }else if (val === "Resend From Etax") {
        this.sortStatus = "resend";
      }else if (val === "Sender Delete") {
        this.sortStatus = "sender_delete";
      }
      console.log("sortStatus", this.sortStatus);

      this.getOutboxDataV3();
    },
    exportfilecsv() {
      // console.log("data",this.inboxdata);
      this.callexportfilecsv();
    },
    // อันเก่าแบบ 3 step
    // async callexportfilecsv() {
    //   this.snackbars = [];
    //   this.percentCompleted = 0;
    //   this.count_loadingfile = 0;
    //   this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
    //   let receiver = [];
    //   for (let i = 0; i < this.dataBusinessProfile.length; i++) {
    //     receiver.push(this.dataBusinessProfile[i]["business_id"]);
    //   }
    //    let payload = {

    //     sender_id: receiver,
    //     sender_type: "2",
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
    //     doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
    //     cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
    //     sender_name: this.sendername,
    //     system: this.$route.query.systemid,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status:this.sortStatus,
    //     customer_box: "",
    //     status_onechat: [],
    //     type_downloads : this.typedownloads == true ? 'Y' : 'N',
    //     type_document : this.typeitem == true ? 'Y' : 'N',
    //     sort_by: this.sort_by,
    //     direction: this.direction,

  
    //   };
    //   // this.snackbardowloadonetotal = true;
    //   this.opensnackbarmultidownload = true;
    //   this.timeout = -1;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
       
    //     }
    //   await this.axios
    //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare/download_csv_file_outbox_byfilter", payload, config)
    //     .then((response) => {
    //       if (response.data.status === "OK" && response.data.total > 0) {
    //         console.log("response", response);
    //         this.fn_loop_multidownloading_csv(response.data.all_file, response.data.zip_name, response.data.url);  
    //         this.count_allfile = response.data.total;
    //       } else {
    //         // this.snackbardowloadonetotal = false;
    //         this.opensnackbarmultidownload = false;
    //         if (response.data.total <= 0) {
    //           Toast.fire({
    //             icon: "error",
    //             title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
    //           });
    //         } else {
    //           Toast.fire({
    //             icon: "error",
    //             title: response.data.errorMessage,
    //           });
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       // this.snackbardowloadonetotal = false;
    //       this.opensnackbarmultidownload = false;
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },

    // แบบใหม่ยิง 2 เส้น 1.จำนวนไฟล์ที่ดาวน์โหลด 2.ดาวน์โหลดไฟล์
    async callexportfilecsv() {
      this.snackbars = [];
      // this.percentCompleted = 0;
      this.percent = 0;
      this.count_allfile = 0;
      // this.count_loadingfile = 0;
      this.showtotalfileonly = true;
      // this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let receiver = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        receiver.push(this.dataBusinessProfile[i]["business_id"]);
      }      
      let offset = this.size * (this.page - 1);
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: receiver,
        sender_type: "2",
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername  === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},       
      }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/V2/count_all_download_file_outbox_byfilter", payload, config)
        .then((response) => {
          if (response.data.status === "ER") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          } else {
            if (response.data.total <= 0) {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              console.log("response--", response);
              this.count_allfile = response.data.total;
              this.download_file_csv();             
            }
          }
        })
        .catch((error) => {
          // this.snackbardowloadonetotal = false;
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesecsv + " นี้ได้",
          });
          console.log(error);
        });
    },
    async download_file_csv() {
      this.snackbars = [];
      this.percentCompleted = 0;
      this.percent = 0;
      // this.count_allfile = 0;
      // this.count_loadingfile = 0;
      let receiver = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        receiver.push(this.dataBusinessProfile[i]["business_id"]);
      }
       let payload = {
        sender_id: receiver,
        sender_type: "2",
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status:this.sortStatus,
        customer_box: "",
        status_onechat: [],
        type_downloads : this.typedownloads == true ? 'Y' : 'N',
        type_document : this.typeitem == true ? 'Y' : 'N',
        sort_by: this.sort_by,
        direction: this.direction,
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
        onDownloadProgress: (progressEvent) => {
          let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progresspercent >= 99) {
            this.percentCompleted = 99;
          } else {
            this.percentCompleted = progresspercent;
          }
          this.percent = this.percentCompleted;
          console.log("percent", this.percent);
        },
        withCredentials: false,
        responseType: "arraybuffer",
      }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/V2/download_csv_file_outbox_byfilter", payload, config)
        .then((response) => {
          if (response.headers["content-type"] === "application/json") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            let res_error = gbfdecodearraybuffer.decodearraybuffer(
              response.data
            );
            Toast.fire({
              icon: "error",
              title: res_error.errorMessage,
            });
          } else {
            setTimeout(() => {
              this.percent = 100;
              console.log("percent100", this.percent);
            }, 1000);
            // this.percentCompleted = 100;
            // this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
            this.btsdownload = false;
            let headers = response.headers;
            let blob = new Blob([response.data], {
              type: headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              format(new Date(), "dd_MM_yyyy") + "_Outbox_CSV";
            link.click();
            setTimeout(() => {
              this.opensnackbarmultidownload = false;
              this.showtotalfileonly = false;
            }, 2500);
          }
        })
        .catch((error) => {
          // this.snackbardowloadonetotal = false;
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesecsv + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_csv(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);


       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ CSV";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_csv(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_csv(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_csv(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish_csv(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_outbox_csv_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
          // if (response.statusText === "OK") {
          if (response.status === 200) {
            console.log("response++++++++++++ OK", response);
            // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
            if (
              parseInt(response.headers["content-length"]) < 700 &&
              (response.headers["content-type"]) === "application/json"
            ) {
              console.log("response < 1000", response);
              this.opensnackbarmultidownload = false;
              // this.snackbardowloadonetotal = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              console.log("responseerror", res_error);
              Toast.fire({
                icon: "error",
                title: res_error.errorMessage,
              });
            } else {
              this.percent= 100;
              this.percentCompleted = 100;
              this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
              this.btsdownload = false;
              let headers = response.headers;
              let blob = new Blob([response.data], { type: headers["content-type"] });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              // link.download = this.OutboxFile + "_" + this.datetime;
              link.download = format(new Date(), "dd_MM_yyyy") + '_Outbox_CSV';
              link.click();
              setTimeout(() => {
                this.opensnackbarmultidownload = false;
                // this.snackbardowloadonetotal = false;
              }, 2500);
            }
          } else {
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
         this.typedownloads = false;
         this.typeitem = false;
        });
      });
    },
  //  async callexportfilecsv() {
  //    this.percentCompleted = 0;
  //     // this.$store.dispatch("show_loading", true);
  //     let receiver = [];
  //     for (let i = 0; i < this.dataBusinessProfile.length; i++) {
  //       receiver.push(this.dataBusinessProfile[i]["business_id"]);
  //     }
  //     // console.log(receiver);

  //     let payload = {
  //       account_id: this.dataAccountId,
  //       sender_id: receiver,
  //       sender_type: "2",
  //       filename: this.filename,
  //       fileextension: this.fileextension,
  //       doc_no_start: this.documentid,
  //       doc_no_end: this.documentidto,
  //       doc_date_start: this.formatDate(this.fromdatedocument),
  //       doc_date_end: this.formatDate(this.todatedocument),
  //       doc_type: this.documenttype,
  //       cre_dtm_start: this.formatDate(this.fromdateupload),
  //       cre_dtm_end: this.formatDate(this.todateupload),
  //       sender_name: this.sendername,
  //       system: this.$route.query.systemid,
  //       sellerTaxid: this.sellertaxid,
  //       sellerBranchid: this.sellerbranchid,
  //       receiver_name: this.receivername,
  //       receiverTaxid: this.buyertaxid,
  //       receiverBranchid: this.buyerbranchid,
  //       status:this.sortStatus
  //     };
  //     this.snackbardowloadonetotalcsv = true;
  //     let auth = await gbfGenerate.generateToken();
  //     let config = {
  //       headers: { Authorization: auth.code},
  //          onDownloadProgress: progressEvent => {
  //             let progresspercent = Math.round(
  //               (progressEvent.loaded * 100) / progressEvent.total
  //             );
  //             // console.log(progresspercent);
  //             if (progresspercent === 95) {
  //               this.percentCompleted = 95;
  //             } else {
  //               this.percentCompleted = progresspercent;
  //             }
  //           },
  //           withCredentials: false,
  //           responseType: "arraybuffer"
  //       }
  //     await this.axios
  //       .post(
  //         process.env.VUE_APP_SERVICE_DOWNLOAD_API +
  //           "/api/download_csv_file_outbox_byfilter",
  //         payload,config
  //       )
  //       .then(response => {
  //         if (response.statusText === "OK") {
  //           if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
  //               console.log("response < 1000", response);
  //               this.snackbardowloadonetotalcsv = false;
  //               let res_error = gbfdecodearraybuffer.decodearraybuffer(
  //                 response.data
  //               );
  //               console.log("responseerror", res_error);
  //               Toast.fire({
  //                 icon: "error",
  //                 title: res_error.errorMessage,
  //               });
  //             } else {

  //               this.percentCompleted = 100;
  //               // const blob = new Blob([response.data]);
  //               // const content = response.headers["content-type"];
  //               // saveAs(blob, this.ExportOutboxFilescsv + ".zip");
  //               let headers = response.headers;
  //               let blob = new Blob([response.data],{type:headers['content-type']});
  //               let link = document.createElement('a');
  //               link.href = window.URL.createObjectURL(blob);
  //               link.download = this.ExportOutboxFilescsv;
  //               link.click();
  //               setTimeout(() => {
  //                 this.snackbardowloadonetotalcsv = false;
  //               }, 2500);
  //             }
  //         }
  //       })
  //       .catch(error => {
  //         Toast.fire({
  //           icon: "error",
  //           title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilescsv + " นี้ได้"
  //         });
  //         console.log(error);
  //       });
  //   },
    exportfileexcel() {
      // console.log("data",this.inboxdata);
      this.callexportfileexcel();
    },
    // อันเก่าแบบ 3 step
    // async callexportfileexcel() {
    // this.snackbars = [];
    //  this.percentCompleted = 0;
    //  this.count_loadingfile = 0;
    //  this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
    //   let receiver = [];
    //   for (let i = 0; i < this.dataBusinessProfile.length; i++) {
    //     receiver.push(this.dataBusinessProfile[i]["business_id"]);
    //   }
    //    let payload = {
    //     // account_id: this.dataAccountId,
    //     sender_id: receiver,
    //     sender_type: "2",
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
    //     doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
    //     cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
    //     sender_name: this.sendername,
    //     system: this.$route.query.systemid,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status:this.sortStatus,
    //     customer_box: "",
    //     status_onechat: [],
    //     type_downloads : this.typedownloads == true ? 'Y' : 'N',
    //     type_document : this.typeitem == true ? 'Y' : 'N',
    //     sort_by: this.sort_by,
    //     direction: this.direction,

    //   };
    //   // this.snackbardowloadonetotal = true;
    //   this.opensnackbarmultidownload = true;
    //   this.timeout = -1;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
       
    //     }
    //   await this.axios
    //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare/download_excel_file_outbox_byfilter", payload, config)
    //     .then((response) => {
    //       if (response.data.status === "OK" && response.data.total > 0) {
    //         console.log("response", response);
    //         this.fn_loop_multidownloading_excel(response.data.all_file, response.data.zip_name, response.data.url);  
    //         this.count_allfile = response.data.total;
    //       } else {
    //         // this.snackbardowloadonetotal = false;
    //         this.opensnackbarmultidownload = false;
    //         if (response.data.total <= 0) {
    //           Toast.fire({
    //             icon: "error",
    //             title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
    //           });
    //         } else {
    //           Toast.fire({
    //             icon: "error",
    //             title: response.data.errorMessage,
    //           });
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       this.opensnackbarmultidownload = false;
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },

    // แบบใหม่ยิง 2 เส้น 1.จำนวนไฟล์ที่ดาวน์โหลด 2.ดาวน์โหลดไฟล์
    async callexportfileexcel() {
      this.snackbars = [];
      // this.percentCompleted = 0;
      this.percent = 0;
      this.count_allfile = 0;
      // this.count_loadingfile = 0;
      this.showtotalfileonly = true;
      // this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let receiver = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        receiver.push(this.dataBusinessProfile[i]["business_id"]);
      }      
      let offset = this.size * (this.page - 1);
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: receiver,
        sender_type: "2",
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername  === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},       
      }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/V2/count_all_download_file_outbox_byfilter", payload, config)
        .then((response) => {
          if (response.data.status === "ER") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          } else {
            if (response.data.total <= 0) {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              console.log("response--", response);
              this.count_allfile = response.data.total;
              this.download_file_excel();              
            }
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้",
          });
          console.log(error);
        });
    },
    async download_file_excel() {
      this.snackbars = [];
      this.percentCompleted = 0;
      this.percent = 0;
      // this.count_allfile = 0;
      // this.count_loadingfile = 0;
      let receiver = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        receiver.push(this.dataBusinessProfile[i]["business_id"]);
      }
       let payload = {
        // account_id: this.dataAccountId,
        sender_id: receiver,
        sender_type: "2",
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status:this.sortStatus,
        customer_box: "",
        status_onechat: [],
        type_downloads : this.typedownloads == true ? 'Y' : 'N',
        type_document : this.typeitem == true ? 'Y' : 'N',
        sort_by: this.sort_by,
        direction: this.direction,
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
        onDownloadProgress: (progressEvent) => {
          let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progresspercent >= 99) {
            this.percentCompleted = 99;
          } else {
            this.percentCompleted = progresspercent;
          }
          this.percent = this.percentCompleted;
          console.log("percent", this.percent);
        },
        withCredentials: false,
        responseType: "arraybuffer",                 
      }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/V2/download_excel_file_outbox_byfilter", payload, config)
        .then((response) => {
          if (response.headers["content-type"] === "application/json") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            let res_error = gbfdecodearraybuffer.decodearraybuffer(
              response.data
            );
            Toast.fire({
              icon: "error",
              title: res_error.errorMessage,
            });
          } else {
            setTimeout(() => {
              this.percent = 100;
              console.log("percent100", this.percent);
            }, 1000);
            // this.percentCompleted = 100;
            // this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
            this.btsdownload = false;
            let headers = response.headers;
            let blob = new Blob([response.data], {
              type: headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              format(new Date(), "dd_MM_yyyy") + "_Outbox_Excel";
            link.click();
            setTimeout(() => {
              this.opensnackbarmultidownload = false;
              this.showtotalfileonly = false;
            }, 2500);
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_excel(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);


       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ Excel";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_excel(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_excel(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_excel(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish_excel(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_file_excel_finish_outbox", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
          // if (response.statusText === "OK") {
          if (response.status === 200) {
            console.log("response++++++++++++ OK", response);
            // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
            if (
              parseInt(response.headers["content-length"]) < 700 &&
              (response.headers["content-type"]) === "application/json"
            ) {
              console.log("response < 1000", response);
              this.opensnackbarmultidownload = false;
              // this.snackbardowloadonetotal = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              console.log("responseerror", res_error);
              Toast.fire({
                icon: "error",
                title: res_error.errorMessage,
              });
            } else {
              this.percent= 100;
              this.percentCompleted = 100;
              this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
              this.btsdownload = false;
              let headers = response.headers;
              let blob = new Blob([response.data], { type: headers["content-type"] });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              // link.download = this.OutboxFile + "_" + this.datetime;
              link.download = format(new Date(), "dd_MM_yyyy") + '_Outbox_Excel';
              link.click();
              setTimeout(() => {
                this.opensnackbarmultidownload = false;
                // this.snackbardowloadonetotal = false;
              }, 2500);
            }
          } else {
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
            this.typedownloads = false;
            this.typeitem = false;
        });
      });
    },
    // async callexportfileexcel() {
    //   this.percentCompleted = 0;
    //   // this.$store.dispatch("show_loading", true);
    //   let receiver = [];
    //   for (let i = 0; i < this.dataBusinessProfile.length; i++) {
    //     receiver.push(this.dataBusinessProfile[i]["business_id"]);
    //   }
    //   // console.log(receiver);

    //   let payload = {
    //     account_id: this.dataAccountId,
    //     sender_id: receiver,
    //     sender_type: "2",
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     sender_name: this.sendername,
    //     system: this.$route.query.systemid,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status:this.sortStatus
    //   };
    //   this.snackbardowloadonetotalexcel = true;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //       onDownloadProgress: progressEvent => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer"
    //     }
    //  await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //         "/api/download_excel_file_outbox_byfilter",
    //       payload,config
    //     )
    //     .then(response => {
    //       if (response.statusText === "OK") {
    //         if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //             console.log("response < 1000", response);
    //             this.snackbardowloadonetotalexcel = false;
    //             let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //               response.data
    //             );
    //             console.log("responseerror", res_error);
    //             Toast.fire({
    //               icon: "error",
    //               title: res_error.errorMessage,
    //             });
    //           } else {

    //             this.percentCompleted = 100;
    //             // const blob = new Blob([response.data]);
    //             // const content = response.headers["content-type"];
    //             // saveAs(blob, this.ExportOutboxFilesexcel + ".zip");
    //             let headers = response.headers;
    //             let blob = new Blob([response.data],{type:headers['content-type']});
    //             let link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = this.ExportOutboxFilesexcel;
    //             link.click();
    //             setTimeout(() => {
    //               this.snackbardowloadonetotalexcel = false;
    //             }, 2500);
    //           }
    //       }
    //     })
    //     .catch(error => {
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้"
    //       });
    //       console.log(error);
    //     });
    // },
    // Function ดาวน์โหลดไฟล์ 1 ต่อ 1
    // downloadfile(currentfile) {
    //   if (currentfile.file_type === "folder") {
    //     Toast.fire({
    //       type: "warning",
    //       title: "Coming Soon !!"
    //     });
    //   } else {
    //     let url =
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //       "/api/v2/download_file?user_id=" +
    //       this.dataUsername +
    //       "&file_id=" +
    //       currentfile.file_id;
    //     window.location.assign(url);
    //   }
    // },
    // fn_calculate_size(_sizefilebyte) {
    //   console.log("ขนาดไฟล์เป็น GB",(_sizefilebyte / 1073741824).toFixed(2));
    //    return parseInt((_sizefilebyte / 1073741824).toFixed(2))
    // },
    downloadfile(currentfile) {
      console.log(currentfile);
      // let checkfilesize = this.fn_calculate_size(currentfile.file_size) 
      this.percentCompleted = 0;
      if (currentfile.file_type === "folder") {
        Toast.fire({
          icon: "warning",
          title: "Coming Soon !!"
        });
      } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id
        };

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file_inbox?user_id=" +
          // "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id;
        // if(checkfilesize < 1.00){
         this.snackbardowloadonefile = true;
         this.namedowload = currentfile.filename;
      this.axios
            .get(url,{
              onDownloadProgress: (progressEvent) => {
                let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // console.log(progresspercent);        
                 if (progresspercent === 95) {
                  this.percentCompleted = 95;
                  }else{
                    this.percentCompleted = progresspercent;
                  }
                
              },
              withCredentials: false,
              responseType: "arraybuffer" 
            })
            .then(response => {
              // if(response.statusText === "OK"){
              if(response.status === 200){
                if (parseInt(response.headers["content-length"]) < 700 && (response.headers["content-type"]) === "application/json") {
                  console.log("response < 1000", response);
                  this.snackbardowloadonefile = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(
                    response.data
                  );
                  console.log("responseerror", res_error);
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                } else {
                  this.percentCompleted = 100; 
                  let headers = response.headers;
                  let blob = new Blob([response.data],{type:headers['content-type']});
                  let link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = currentfile.filename;
                  link.click(); 
                  setTimeout(() => {
                    this.snackbardowloadonefile = false;
                  }, 2500);
                }
              }else{
                this.snackbardowloadonefile = false;
                Toast.fire({
                  icon: "error",
                  title: this.$t("toast.cannotconnectonebox"),
                });
              }
              // const blob = new Blob([response.data]);
              // const content = response.headers['content-type'];
              // saveAs(blob,currentfile.filename)
                
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.filename + " นี้ได้"
              });
              console.log(error);
            });
        // }else{
        //   window.location.assign(url)
        //   this.btsdownload = false;
        //   this.snackbardowloadonefile = false;
        // }
      }
    },
    // Function ดูไฟล์
    previewfile(file) {
      // console.log(file);
      this.currentfile = file;
      this.openpreviewfile = true;
    },
    // Function โหลดข้อมูลจำนวนการใช้งานที่ใช้ไป
    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then(res => {});
    },
    // Function บันทึกวันที่ลงตัวแปร
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      // this.todateupload = "";
      this.todateupload = this.formatDatetodoc2(this.fromdateupload);
    },
    // Function บันทึกวันที่ลงตัวแปร
    changeFromdatedocument() {
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);
      // this.todatedocument = "";
      this.todatedocument = this.formatDatetodoc2(this.fromdatedocument);
    },
    // Function ตั้ง ICON FILES
    seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image", "blue-grey"];
      } else if (parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4"||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv")
      {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut"){
        dataicon=["mdi-file-link","pink"]
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },
    // Function set format วันที่ จาก YYYY-MM-DD เป็น YYYYMMDD
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    formatDatefromdoc(datefrom,docto) {
      console.log("datefrom1",datefrom);
      console.log("dateto2",docto);
      if((!datefrom || datefrom === undefined)&& docto){
        console.log('1');
        if(docto){
          console.log('2');
          console.log("docto", docto);
          var date = new Date(docto);
          date.setDate(date.getDate() - 90);
          console.log("date",date);
          this.fromdatedocument = new Date(date).toISOString().substr(0, 10);
          console.log("this.fromdatedocument",this.fromdatedocument);
          const [year, month, day] = this.fromdatedocument.split("-");
          return `${year}${month}${day}`;
        }
      }else if(datefrom && docto){
        console.log('3');
        const [year, month, day] = datefrom.split("-");
        return `${year}${month}${day}`;
      }else if((!datefrom || datefrom === undefined ) && (!docto || docto === undefined)){
        return ""
      }else if(datefrom && (!docto || docto === undefined)){
        console.log('2');
          console.log("datefrom-", datefrom);
          var dateNow = new Date();
          var date = new Date(datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.fromdatedocument =new Date(datefrom).toISOString().substr(0, 10);
            this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.fromdatedocument.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.fromdatedocument =new Date(datefrom).toISOString().substr(0, 10);
            this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.fromdatedocument.split("-");
            return `${year}${month}${day}`;
          }
      }
    },
    formatDatetodoc(datefrom,docto) {
      console.log("datefrom",datefrom);
      console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
      if(!docto && datefrom){
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.todatedocument.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.todatedocument.split("-");
            return `${year}${month}${day}`;
          }
        }
      }else if(docto && datefrom){
        console.log('3');
        const [year, month, day] = docto.split("-");
        return `${year}${month}${day}`;
      }else if(!docto && !datefrom){
        return ""
      }
    },
    formatDatefromupload(datefrom,docto) {
      console.log("date",datefrom);
      console.log("date",docto);
      if(!datefrom){
        console.log('1');
        if(docto){
          console.log('2');
          console.log("docto", docto);
          var date = new Date(docto);
          date.setDate(date.getDate() - 90);
          console.log("date",date);
          this.fromdateupload = new Date(date).toISOString().substr(0, 10);
          console.log("this.fromdateupload",this.fromdateupload);
          const [year, month, day] = this.fromdateupload.split("-");
          return `${year}${month}${day}`;
        }
      }else{
        console.log('3');
        const [year, month, day] = datefrom.split("-");
        return `${year}${month}${day}`;
      }
    },
    formatDatetoupload(datefrom,docto) {
      console.log("datefrom",datefrom);
      console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
      if(!docto && datefrom){
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.todateupload = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.todateupload.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.todateupload = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.todateupload.split("-");
            return `${year}${month}${day}`;
          }
        }
      }else if(docto && datefrom){
        console.log('3');
        const [year, month, day] = docto.split("-");
        return `${year}${month}${day}`;
      }else if(!docto && !datefrom){
        return ""
      }
    },
    formatDatetodoc2(datefrom) {
      console.log("datefrom",datefrom);
      // console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            let todate = new Date().toISOString().substr(0, 10);
            // this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = todate.split("-");
            return `${year}-${month}-${day}`;
          }else{
            console.log('4');
            let todate = new Date(date).toISOString().substr(0, 10);
            // this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = todate.split("-");
            return `${year}-${month}-${day}`;
          }
        }

    },
    // Function set format วันที่และเวลา จาก YYYYMMDDhhmmss เป็น YYYY/MM/DD HH:mm:ss
    formatdatetime(_datetime) {
      if(_datetime === "" || _datetime === null || _datetime === undefined){
        return "-";
      }else{
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[8] + _datetime.split("")[9];
        let second = _datetime.split("")[10] + _datetime.split("")[11];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    // Function set format วันที่เป็น YYYY/MM/DD
    setformatdate(_date) {
      if(_date === "" || _date === null || _date === undefined){
        return "-";
      }else{
        let data =
          _date.substring(8, 6) +
          "/" +
          _date.substring(6, 4) +
          "/" +
          _date.substring(0, 4);
        return data || "-";
      } 
    },
    // Function set format วันที่เป็น YYYY/MM/DD
    searchdocument() {
      if (
        (this.filename === "" || this.filename === null) &&
        (this.fileextension === "" || this.fileextension === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null)  &&
        (this.receivername === "" || this.receivername === null ) &&
        (this.sendername === "" || this.sendername === null) &&
        this.documenttype.length == 0 &&
        (this.documentid === "" ||this.documentid === null ) &&
        (this.documentidto === "" || this.documentidto === null) &&
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null ) &&
        (this.sellertaxid === "" || this.sellertaxid === null) &&
        (this.sellerbranchid === "" || this.sellerbranchid === null) &&
        (this.buyertaxid === "" || this.buyertaxid === null)&&
        (this.buyerbranchid === "" || this.buyerbranchid === null)
      ){
          this.opendialogalersearchdonotfillout = true;
      }else if(
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) &&
         (this.documentid === "" || this.documentid === null) 
      ){
        this.opendialogalersearchdonotfillout = true;
      }else if(
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) && (
        (this.documentid !== "" || this.documentid !== null) ||
        (this.documentidto !== "" || this.documentidto !== null) )
      ){
        this.page =1
      this.panel = false;
      this.getSourceSystem();
      }else if(
        (this.fromdatedocument !== ""  &&
        this.todatedocument !== ""  ) ||
        (this.fromdateupload !== ""  &&
        this.todateupload !== "" )
      ){
        this.page =1
      this.panel = false;
      this.getSourceSystem();
      }else{
        this.page =1
      this.panel = false;
      this.getSourceSystem();
      }
     
    },

    // 23/02/2020
    // Function โหลด Filter
    getDataFilters() {
      this.getDataDocType(this.dataAccountId, "1");
      // this.getDataExtensionFile(this.dataAccountId, "1");
      // if (this.dataAccountActive["type"] === "Business") {
      //   this.loadSourceSystem(_accountid, _type)
      // } else {
      //   this.loadSourceSystem(_accountid, _type)
      // }
    },
    // Function โหลด ประเภทเอกสาร
   async getDataDocType(_accountid, _type) {
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type,
        system: this.$route.query.sourcesystem || ""
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_all_doctype",
          payload,{ headers: { Authorization: auth.code },},
        )
        .then(res => {
          if (res.data.status === "OK") {
            this.listDoctype = res.data.result;
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // Function โหลด สกุลไฟล์
  async  getDataExtensionFile(_accountid, _type) {
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type,
        system: this.$route.query.sourcesystem || ""
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX +
            "/api/get_all_fileextension",
          payload, { headers: { Authorization: auth.code },},
        )
        .then(res => {
          if (res.data.status === "OK") {
            this.listExtensionFile = res.data.result;
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // Function ยิง API ดาวน์โหลดไฟล์ทั้งหมดตาม Filter
  //  async callexportfile() {
  //    this.percentCompleted = 0;
  //     // this.$store.dispatch("show_loading", true);
  //     let receiver = [];
  //     for (let i = 0; i < this.dataBusinessProfile.length; i++) {
  //       receiver.push(this.dataBusinessProfile[i]["business_id"]);
  //     }
  //     let payload = {
  //       sender_id: receiver,
  //       sender_type: "2",
  //       filename: this.filename,
  //       fileextension: this.fileextension,
  //       doc_no_start: this.documentid,
  //       doc_no_end: this.documentidto,
  //       doc_date_start: this.formatDate(this.fromdatedocument),
  //       doc_date_end: this.formatDate(this.todatedocument),
  //       doc_type: this.documenttype,
  //       cre_dtm_start: this.formatDate(this.fromdateupload),
  //       cre_dtm_end: this.formatDate(this.todateupload),
  //       system: this.$route.query.systemid,
  //       sender_name: this.sendername,
  //       sellerTaxid: this.sellertaxid,
  //       sellerBranchid: this.sellerbranchid,
  //       receiver_name: this.receivername,
  //       receiverTaxid: this.buyertaxid,
  //       receiverBranchid: this.buyerbranchid,
  //       status:this.sortStatus
  //     };
  //     this.snackbardowloadonetotal = true;
  //     let auth = await gbfGenerate.generateToken();
  //     let config = {
  //       headers: { Authorization: auth.code},
  //         onDownloadProgress: progressEvent => {
  //             let progresspercent = Math.round(
  //               (progressEvent.loaded * 100) / progressEvent.total
  //             );
  //             // console.log(progresspercent);
  //             if (progresspercent === 95) {
  //               this.percentCompleted = 95;
  //             } else {
  //               this.percentCompleted = progresspercent;
  //             }
  //           },
  //           withCredentials: false,
  //           responseType: "arraybuffer"
  //       }
  //     await this.axios
  //       .post(
  //         process.env.VUE_APP_SERVICE_DOWNLOAD_API +
  //           "/api/v2/outbox_downloads_file_byfilter",
  //         payload,config
  //       )
  //       .then(response => {
  //         if (response.statusText === "OK") {
  //           if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
  //               console.log("response < 1000", response);
  //               this.snackbardowloadonetotal = false;
  //               let res_error = gbfdecodearraybuffer.decodearraybuffer(
  //                 response.data
  //               );
  //               console.log("responseerror", res_error);
  //               Toast.fire({
  //                 icon: "error",
  //                 title: res_error.errorMessage,
  //               });
  //             } else {
  //               this.percentCompleted = 100;
  //               // const blob = new Blob([response.data]);
  //               // const content = response.headers["content-type"];
  //               // saveAs(blob, this.ExportOutboxFiles + ".zip");
  //               let headers = response.headers;
  //               let blob = new Blob([response.data],{type:headers['content-type']});
  //               let link = document.createElement('a');
  //               link.href = window.URL.createObjectURL(blob);
  //               link.download = this.ExportOutboxFiles;
  //               link.click();
  //               setTimeout(() => {
  //                 this.snackbardowloadonetotal = false;
  //               }, 2500);
  //             }
  //         }
  //       })
  //       .catch(error => {
  //         Toast.fire({
  //           icon: "error",
  //           title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้"
  //         });
  //         console.log(error);
  //       });
  //   },
    // Function เรียก callexportfile
    exportfile() {
      this.callexportfile();
    },
     async callexportfile() {
      this.percentCompleted = 0;
      this.count_loadingfile = 0;
      this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let receiver = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        receiver.push(this.dataBusinessProfile[i]["business_id"]);
      }
      let payload = {
        sender_id: receiver,
        sender_type: "2",
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status:this.sortStatus,
        account_id: this.dataAccountId,
        type_downloads : this.typedownloads == true ? 'Y' : 'N',
        type_document : this.typeitem == true ? 'Y' : 'N'
      };
      // this.snackbardowloadonetotal = true;
        this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
        // onDownloadProgress: (progressEvent) => {
        //       let progresspercent = Math.round(
        //         (progressEvent.loaded * 100) / progressEvent.total
        //       );
        //       // console.log(progresspercent);
        //       if (progresspercent === 95) {
        //         this.percentCompleted = 95;
        //       } else {
        //         this.percentCompleted = progresspercent;
        //       }
        //     },
        //     withCredentials: false,
        //     responseType: "arraybuffer",
        }
      await this.axios
        // .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v2/outbox_downloads_file_byfilter", payload, config)
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare_zip_file_outbox_by_filter", payload, config)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.fn_loop_multidownloading(response.data.all_file, response.data.zip_name, response.data.url); 
            this.count_allfile = response.data.total; 
          // if (response.statusText === "OK") {
          //   if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
          //       console.log("response < 1000", response);
          //       this.snackbardowloadonetotal = false;
          //       let res_error = gbfdecodearraybuffer.decodearraybuffer(
          //         response.data
          //       );
          //       console.log("responseerror", res_error);
          //       Toast.fire({
          //         icon: "error",
          //         title: res_error.errorMessage,
          //       });
          //     } else {
          //       this.percentCompleted = 100;
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                // saveAs(blob, this.ExportOutboxFiles + ".zip");
          //       let headers = response.headers;
          //       let blob = new Blob([response.data],{type:headers['content-type']});
          //       let link = document.createElement('a');
          //       link.href = window.URL.createObjectURL(blob);
          //       link.download = this.ExportOutboxFiles;
          //       link.click();
          //       setTimeout(() => {
          //         this.snackbardowloadonetotal = false;
          //       }, 2500);
          //     }
          // }
          } else {
            // this.snackbardowloadonetotal = false;
            this.opensnackbarmultidownload = false;
            if (response.data.errorCode === "ER400" && response.data.errorMessage === "File not found") {
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          }
        })
        .catch((error) => {
          this.snackbardowloadonetotal = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
          });
          console.log(error);
        });
    },
      async fn_loop_multidownloading(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);


       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังดาวน์โหลดไฟล์";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_file_inbox_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
          // if (response.statusText === "OK") {
          if (response.status === 200) {
            console.log("response++++++++++++ OK", response);
            // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
            if (
              parseInt(response.headers["content-length"]) < 700 &&
              (response.headers["content-type"]) === "application/json"
            ) {
              console.log("response < 1000", response);
              this.opensnackbarmultidownload = false;
              this.snackbardowloadonetotal = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              console.log("responseerror", res_error);
              Toast.fire({
                icon: "error",
                title: res_error.errorMessage,
              });
            } else {
              this.percent= 100;
              this.percentCompleted = 100;
              this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
              this.btsdownload = false;
              let headers = response.headers;
              let blob = new Blob([response.data], { type: headers["content-type"] });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              // link.download = this.OutboxFile + "_" + this.datetime;
              link.download = format(new Date(), "dd_MM_yyyy") + '_OutboxFiles';
              link.click();
              setTimeout(() => {
                this.opensnackbarmultidownload = false;
                this.snackbardowloadonetotal = false;
              }, 2500);
            }
          } else {
            this.opensnackbarmultidownload = false;
            this.snackbardowloadonetotal = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
          this.typedownloads = false;
          this.typeitem = false;
        });
      });
    },
    Clear_data(){
      this.ponumberinput = '',
      this.filename = '',
      this.fileextension = [],
      this.fromdateupload = '',
      this.todateupload = '',
      this.receivername = '',
      this.documenttype = [],
      this.sendername = '',
      this.documentid = '',
      this.documentidto = '',
      this.fromdatedocument = '',
      this.todatedocument = '',
      this.buyertaxid = '',
      this.buyerbranchid = '',
      this.sellertaxid = '',
      this.sellerbranchid = ''
    },

    // Function กด ค้นหา
    // searchdata() {
    //   this.totalfile = 0;
    //   this.page = 1
    //   if (this.datatype["id"] === 1) {
    //     this.getOutboxReceiverV2();
    //   } else {
    //     this.getOutboxDataV3();
    //   }
    // },
    searchdata() {
      // console.log("this.filename",this.filename);
      // console.log("this.fileextension",this.fileextension);
      // console.log("this.fromdateupload",this.fromdateupload);
      // console.log("this.todateupload",this.todateupload);
      // console.log("this.receivername",this.receivername);
      // console.log("this.statusonechat",this.statusonechat);
      // console.log("this.sendername",this.sendername);
      // console.log("this.statusonechat",this.statusonechat);
      // console.log("this.documenttype",this.documenttype);
      // console.log("this.documentid",this.documentid);
      // console.log("this.documentidto",this.documentidto);
      // console.log("this.fromdatedocument",this.fromdatedocument);
      // console.log("this.todatedocument",this.todatedocument);
      // console.log("this.sellertaxid",this.sellertaxid);
      // console.log("this.sellerbranchid",this.sellerbranchid);
      // console.log("this.buyertaxid",this.buyertaxid);
      // console.log("this.buyerbranchid",this.buyerbranchid);
      if (
        (this.ponumberinput === "" || this.ponumberinput === null) &&
        (this.filename === "" || this.filename === null) &&
        (this.fileextension === "" || this.fileextension === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null)  &&
        (this.receivername === "" || this.receivername === null ) &&
        (this.sendername === "" || this.sendername === null) &&
        this.documenttype.length == 0 &&
        (this.documentid === "" ||this.documentid === null ) &&
        (this.documentidto === "" || this.documentidto === null) &&
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null ) &&
        (this.sellertaxid === "" || this.sellertaxid === null) &&
        (this.sellerbranchid === "" || this.sellerbranchid === null) &&
        (this.buyertaxid === "" || this.buyertaxid === null)&&
        (this.buyerbranchid === "" || this.buyerbranchid === null)
      ){
          this.opendialogalersearchdonotfillout = true;
      }else if(
        (this.ponumberinput === "" || this.ponumberinput === null) &&
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) &&
         (this.documentid === "" || this.documentid === null) 
      ){
        this.opendialogalersearchdonotfillout = true;
      }
      // else if(
      //   (this.fromdatedocument === "" || this.fromdatedocument === null) &&
      //   (this.todatedocument === "" || this.todatedocument === null) &&
      //   (this.fromdateupload === "" || this.fromdateupload === null) &&
      //   (this.todateupload === "" || this.todateupload === null) && (
      //   (this.documentid !== "" || this.documentid !== null) ||
      //   (this.documentidto !== "" || this.documentidto !== null) )
      // ){
      //   this.totalfile = 0;
      // this.page = 1
      // if (this.datatype["id"] === 1) {
      //   this.getOutboxReceiverV2();
      // } else {
      //   this.getOutboxDataV3();
      // }
      // }else if(
      //   (this.fromdatedocument !== ""  &&
      //   this.todatedocument !== ""  ) ||
      //   (this.fromdateupload !== ""  &&
      //   this.todateupload !== "" )
      // ){
      //   this.totalfile = 0;
      // this.page = 1
      // if (this.datatype["id"] === 1) {
      //   this.getOutboxReceiverV2();
      // } else {
      //   this.getOutboxDataV3();
      // }
      // }
      else{
        this.totalfile = 0;
      this.page = 1
      if (this.datatype["id"] === 1) {
        this.getOutboxReceiverV2();
      } else {
        this.getOutboxDataV3();
      }
      }


    },
    // Function กด Pagination
    clickpagination() {
      if(this.checkpagination === true){
        this.checksortorderby();
      }else{
        // this.searchdata();
      if (this.datatype["id"] === 1) {
        // this.getOutboxReceiverV2();
      } else {
        this.totalfile = 0;
        this.getOutboxDataV3();
      }
      }
      
    },

    async getOutboxReceiverV2() {
      this.outboxdata = [];
      let i;
      let type_code = this.documenttype.find(x => x === 'T05')
      if(type_code === 'T05'){
        let code_ = this.documenttype.find(x => x === 'T06')
        if(code_ !== 'T06'){
          
          this.documenttype.push("T06");
        }
      }else{
          let index = this.documenttype.findIndex(x => x === 'T06')
          console.log("index",index)
          if(index != -1){
             this.documenttype.splice(index,1)
          } 
      }
      if (this.source) {
          this.source.cancel()
      }
      this.source = CancelToken.source()
      const cancelToken = this.source.token

      this.loading_company = 0;
      this.total_download = this.dataBusinessProfile.length;
      try {
        for (i = 0; i < this.dataBusinessProfile.length; i++) {
          let _payload = {
            po_number: this.ponumberinput === null ? "" : this.ponumberinput,
            sender_id: this.dataBusinessProfile[i]["business_id"],
            sender_type: "2",
            filename: this.filename,
            fileextension: this.fileextension,
            doc_no_start: this.documentid,
            doc_no_end: this.documentidto,
            doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
            doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
            doc_type: this.documenttype,
            cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
            cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
            system: this.$route.query.systemid,
            sellerTaxid: this.sellertaxid,
            sellerBranchid: this.sellerbranchid,
            receiverTaxid:this.buyertaxid,
            receiverBranchid:this.buyerbranchid,
          };
          let auth = await gbfGenerate.generateToken();
          const response = await this.axios({
            method: 'POST',
            withCredentials: false,
            headers: { Authorization: auth.code },
            url: process.env.VUE_APP_SERVICE_GET_OUTBOX + "/api/v2/get_all_sender",
            cancelToken: cancelToken,
            data:_payload
          })
          this.loaddataprogress = false;
          this.loading_company +=1;
          if(this.loading_company == this.dataBusinessProfile.length){
            this.openload = false;
          }else{
            this.openload = true;
          }
          
          if (response.data.status === "OK") {
            if (response.data.result["sum_receive"] > 0) {
              this.totalfile += 1;
              let payload = {
                sender_id: _payload["sender_id"],
                sender_name: response.data.result["sender_name"],
                // fullname_eng: response.data.result["fullname_eng"],
                // fullname_th: response.data.result["fullname_th"],
                sum_receive: response.data.result["sum_receive"]
              };
              this.outboxdata.push(payload);
              this.outboxdata.sort((a, b) => b.sum_receive - a.sum_receive);
            }
            this.filemultipledownload = [];
          } else {
            // Toast.fire({
            //   icon: "error",
            //   title: response.data.errorMessage
            // });
            // console.log(response.data);
          }
        }
      } catch (ex) {
        console.log(ex);
        this.loaddataprogress = false;
      }
    },
    getOutboxDataV3() {
      this.openload = false
      this.outboxdata = [];
      let business = [];
      for (let i = 0; i < this.dataBusinessProfile.length; i++) {
        business.push(this.dataBusinessProfile[i]["business_id"]);
      }
      this.loadDataOutboxV3(business, "2");
    },
    async loadDataOutboxV3(_accountid, _type) {
      this.checkloaddata = true;
      // if(this.statusde == 'Active'){
      //    this.sortStatus = 'active'
      // }
      this.loaddataprogress = true;
      let offset = this.size * (this.page - 1);
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: _accountid,
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername  === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      if (this.source) {
        this.source.cancel()
      }
      this.source = CancelToken.source()
      const cancelToken = this.source.token

      let auth = await gbfGenerate.generateToken();
      try {
        const res = await this.axios({
          method: 'POST',
          headers: { Authorization: auth.code },
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v3/get_file_outbox",
          cancelToken: cancelToken,
          data:payload
        })
        if (res.data.status === "OK") {
          // this.outboxdata = [];
          if ("total_file" in res["data"]["result"]) {
            this.totalfile = res.data.result["total_file"] || 0;
            for(let i=0 ; i < res.data.result.data.length ; i++){
            //   // if(res.data.result.data[i].relation_status === 0 || res.data.result.data[i].relation_status === 2){
                this.outboxdata.push(res.data.result.data[i])
            //   // }
            }
            // console.log(this.outboxdata);
            // this.outboxdata = res.data.result["data"];
          } else {
            this.totalfile = 0;
            this.outboxdata = [];
          }
          this.checkloaddata = false;
          this.loaddataprogress = false;
        } else {
          this.checkloaddata = false;
          this.loaddataprogress = false;
          this.totalfile = 0;
        }
      }catch(error) {
        this.checkloaddata = false;
        this.loaddataprogress = false;
        this.totalfile = 0;
        console.log(error);
      }
    },
    async multipledownloadbycompany() {
      this.$store.dispatch("show_loading", true);
      let receiver = [];
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        receiver.push(this.filemultipledownload[i]["sender_id"]);
      }
      let payload = {
        sender_id: receiver,
        sender_type: "2",
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/multiple_sender_downloads_byfilter",
          payload,
          {
            headers: { Authorization: auth.code },
            withCredentials: false,
            responseType: "blob"
          }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "OutboxFilesByCompany.zip");
          document.body.appendChild(link);
          link.click();

          this.$store.dispatch("show_loading", false);
        })
        .catch(error => {
          this.$store.dispatch("show_loading", false);
          console.log(error);
        });
    },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
   async multipledownloadbyfiles() {
      this.$store.dispatch("show_loading", true);
      let filedata = [];
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        filedata.push(this.filemultipledownload[i]["file_id"]);
      }

      let payload = {
        list_file: filedata,
        account_id: this.dataAccountId
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
            "/api/multiple_downloads_file",
          payload,
          {
            headers: { Authorization: auth.code },
            withCredentials: false,
            responseType: "blob"
          },
        )
        .then(response => {
          this.statusmutipledownload = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "OutboxFile.zip");
          document.body.appendChild(link);
          link.click();
          this.$store.dispatch("show_loading", false);
          this.clearmultipledownload();
        })
        .catch(error => {
          this.$store.dispatch("show_loading", false);
          Toast.fire({
            icon: "error",
            title: this.$t("myoutboxPage.msg.apierror")
          });
          console.log(error);
        });
    },
    multipledownload() {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          type: "warning",
          title: this.$t("myoutboxPage.msg.nofile")
        });
      } else {
        if (this.datatype["id"] === 1) {
          this.multipledownloadbycompany();
        } else {
          this.multipledownloadbyfiles();
        }
      }
    },
    gotosourcesystem(_business) {
      this.$router.push({
        path: "/business/alloutbox-sourcesystem",
        query: {
          type: 2,
          sourcesystem: this.$route.query.sourcesystem,
          systemid: this.$route.query.systemid,
          biz: _business["sender_id"],
          filename: this.filename,
          fileextension: this.fileextension,
          fromdate: this.fromdateupload,
          todate: this.todateupload,
          fromdatedoc: this.fromdatedocument,
          todatedoc: this.todatedocument,
          nameen: _business["sender_name"],
          nameth: _business["sender_name"],
          doc_no_start: this.documentid,
          doc_no_end: this.documentidto,
          sender_name: this.sendername,
          sellerTaxid: this.sellertaxid,
          sellerBranchid: this.sellerbranchid,
          receiver_name: this.receivername,
          receiverTaxid: this.buyertaxid,
          receiverBranchid: this.buyerbranchid
        }
      });
    },
    callDateNow() {
      let dateNow = new Date();
      return new Date(dateNow).toISOString().substr(0, 10);
    },
  },
  mounted() {
    this.ponumberinput = this.$route.query.po_number || "",
    this.fromdateupload = this.$route.query.fromdate || "";
    this.todateupload = this.$route.query.todate || "";
    this.filename = this.$route.query.filename || "";
    this.fileextension = this.$route.query.fileextension || "";
    this.fromdateupload = this.$route.query.fromdate || "";
    this.todateupload = this.$route.query.todate || "";
    this.fromdatedocument = this.$route.query.fromdatedoc || "";
    this.todatedocument = this.$route.query.todatedoc || "";
    this.documentid = this.$route.query.doc_no_start || "";
    this.documentidto = this.$route.query.doc_no_end || "";
    this.sendername = this.$route.query.sender_name || "";
    this.sellertaxid = this.$route.query.sellerTaxid || "";
    this.sellerbranchid = this.$route.query.sellerBranchid || "";
    this.receivername = this.$route.query.receiver_name || "";
    this.buyertaxid = this.$route.query.receiverTaxid || "";
    this.buyerbranchid = this.$route.query.receiverBranchid || "";
    // this.getSourceSystem();
    this.getDataFilters();
    // this.searchdata();
    //   console.log(this.$router.app['name'])
  },
  beforeDestroy() {
    this.source.cancel()
  }
};
</script>

<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#thiscontainer {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>